/* footer */

.footer {
    border-top: 1px solid $grey;
    margin-top: 20px;
    padding-top: 10px;
    a:hover,
    a:focus {
        text-decoration: underline;
    }
    strong {
        font-family: Arial Black, Arial Bold, Gadget, sans-serif;
        font-weight: normal;
    }
}

@media all and (max-width: 700px) {
    .footer-beta {
        margin-top: 10px;
    }
}

@media all and (min-width: 701px) {
    .footer {
        display: flex;
        justify-content: space-between;
    }
    .footer-beta {
        text-align: right;
    }
}

@media all and (min-width: 1100px) {
    .footer-alpha {
        display: flex;
        p+p {
            margin-left: 60px;
        }
    }
}