/* fonts */

@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=40b3601a-8232-4013-b4a2-7066540bd4f4&fontids=5608561");
@font-face {
    font-family: "Arial MT W01 Black";
    src: url("/fonts/5608561/dbfce1da-7b55-4d7a-830d-c6557d33c24f.woff2") format("woff2"), url("/fonts/5608561/f552a731-c20f-4737-abd7-fef07295897e.woff") format("woff");
}

.f-logo {
    font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
    font-weight: normal;
    color: $medium;
    font-size: 26px;
    line-height: 30px;
    @media all and (max-width: 600px) {
        font-size: 20px;
        line-height: 24px;
    }
}

.f-menu {
    font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
    font-weight: normal;
    color: $medium;
    font-size: 18px;
    line-height: 20px;
    @media all and (max-width: 600px) {
        font-size: 16px;
        line-height: 20px;
    }
}

.f-footer {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: $dark;
    font-size: 14px;
    line-height: 20px;
    @media all and (max-width: 600px) {
        font-size: 12px;
        line-height: 16px;
    }
}

.f-h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: $highlight;
    font-size: 45px;
    line-height: 54px;
    margin: 0;
}

.f-h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: $dark;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 4px;
}

.f-h3 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: $dark;
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 24px;
    @media all and (max-width: 600px) {
        padding-bottom: 0;
    }
}

.f-h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: $dark;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 8px;
}

.f-bodyp {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    color: $dark;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 8px;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        // todo
    }
    &:focus {
        // todo
    }
}

.arialblack {
    font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif !important;
}