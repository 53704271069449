@media all and (min-width: 800px) {
    ul.contentlist,
    .contentlist_wrapper {
        padding-top: 40px;
    }
    .contentlist li,
    .contentlist_wrapper {
        column-count: 2;
        column-gap: 20px;
        padding-bottom: 40px;
        &:last-of-type {
            padding-bottom: 0;
        }
        .f-h2 {
            padding-bottom: 15px;
            font-weight: normal;
            font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
        }
        .f-h3 {
            font-weight: normal;
        }
    }
}

.contentlist__beta {
    break-inside: avoid;
}

@media all and (max-width: 799px) {
    .contentlist li,
    .contentlist_wrapper {
        padding-bottom: 25px;
        &:last-of-type {
            padding-bottom: 0;
        }
        .f-h2 {
            padding-top: 10px;
            padding-bottom: 0;
        }
        .f-h3 {
            font-weight: normal;
            padding-bottom: 5px;
        }
        img {
            margin: 0 -20px;
            max-width: calc(100% + 40px);
            min-width: calc(100% + 40px);
        }
    }
    .contentlist li {
        padding-bottom: 35px;
    }
    .contentlist_wrapper img {
        display: none;
    }
}