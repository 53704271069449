@media all and (min-width: 800px) {
    ul.projectlist {
        padding-top: 10px;
    }
    .projectlist li {
        column-count: 2;
        column-gap: 20px;
        margin-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid $medium;
        &:last-of-type {
            padding-bottom: 0;
            border-bottom: none;
        }
        .f-h2 {
            font-weight: normal;
            font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
        }
        .f-h3 {
            font-weight: normal;
            padding-bottom: 0;
        }
        .f-h3+.f-h3 {
            padding-bottom: 24px;
        }
        br {
            display: block;
            margin: 4px;
        }
    }
}

.projectlist__beta {
    break-inside: avoid;
}

@media all and (max-width: 799px) {
    .projectlist li {
        padding-bottom: 35px;
        &:last-of-type {
            padding-bottom: 0;
        }
        .f-h2 {
            padding-top: 10px;
            padding-bottom: 0;
        }
        .f-h3 {
            font-weight: normal;
            padding-bottom: 5px;
        }
        img {
            margin: 0 -20px;
            max-width: calc(100% + 40px);
            min-width: calc(100% + 40px);
        }
    }
}

// gallery overlay
.gallery-trigger {
    position: relative;
    button {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: none;
        border: none;
        text-indent: -2020em;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
}

.has-js .gallery-trigger button {
    display: block !important;
}

.dialog[aria-hidden="true"] {
    display: none;
}

[aria-hidden="true"] .dialog-overlay {
    opacity: 0;
    z-index: -10;
    transition: opacity 340ms;
}

.dialog-overlay {
    position: fixed;
    top: 0;
    lf: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $light;
    height: 100%;
    opacity: 1;
    z-index: 0;
    transition: opacity 340ms;
}

.dialog-content {
    width: 1196px;
    max-width: calc(100% - 40px);
    top: 60px;
    max-height: 90vh;
    margin: 0 auto;
    border: none;
    background: none;
    position: absolute;
}

.dialog-close {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 50px;
    background: none;
    border: none;
    background: transparent url(/img/icons/close.svg) no-repeat 0 0;
    width: 28px;
    height: 23px;
    display: block;
    padding: 0;
    text-indent: -2020em;
    &:focus {
        outline: none;
    }
}

figcaption {
    text-align: center;
    padding-top: 20px;
}

figure img {
    max-width: auto;
    width: 100%;
}

// ugly hack - don't ask
.tns-outer .tns-outer .tns-outer .tns-controls {
    display: none !important;
}

.tns-outer {
    position: relative;
    max-height: 83vh;
    img {
        object-fit: contain;
    }
    li {
        height: 83vh;
        img {
            object-fit: contain;
            display: block;
            height: calc(83vh - 40px);
        }
    }
}

[data-controls="next"] {
    background: none;
    border: none;
    position: absolute;
    bottom: 0;
    right: 0;
    background: $light url(/img/icons/next.svg) no-repeat 0 0;
    width: 32px;
    height: 32px;
    display: block;
    background-size: cover;
    z-index: 1000;
    text-indent: -2020em;
}

[data-controls="prev"] {
    background: none;
    border: none;
    position: absolute;
    bottom: 0;
    left: 0;
    background: $light url(/img/icons/previous.svg) no-repeat 0 0;
    width: 32px;
    height: 32px;
    display: block;
    background-size: cover;
    z-index: 1000;
    text-indent: -2020em;
}

.tns-controls:focus {
    outline: none;
}