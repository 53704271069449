ul.projectsthumbnails {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 0 -10px;
    li {
        height: 200px;
        flex-grow: 1;
        margin: 40px 10px 0 10px;
        max-width: calc(25% - 20px);
    }
    a {
        display: block;
        height: 100%;
        width: 100%;
    }
    img {
        height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
    }
}

@media all and (max-width: 850px) {
    ul.projectsthumbnails li {
        max-width: calc(33.333% - 20px);
    }
}

@media all and (max-width: 550px) {
    ul.projectsthumbnails {
        margin-top: -20px;
    }
    ul.projectsthumbnails li {
        margin: 20px 10px 0 10px;
        max-width: calc(50% - 20px);
    }
}

@media all and (max-width: 360px) {
    ul.projectsthumbnails li {
        max-width: calc(100% - 20px);
    }
}