/* layout */

body {
    min-height: 110vh;
    background: $light;
    padding-top: 70px;
    padding-bottom: 20px;
    @media all and (max-width: 800px) {
        padding-top: 15px;
    }
}

.wrapper {
    width: 1196px;
    max-width: calc(100% - 40px);
    margin-left: auto;
    margin-right: auto;
}

img {
    max-width: 100%;
    height: auto;
}

@media all and (max-width: 800px) {
    .img--full {
        max-width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
    }
}

.imgwrapper-home {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    span {
        display: none;
        @media all and (max-width: 600px) {
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            color: $highlight;
            margin: auto;
            font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
            text-align: center;
            font-size: 40px;
        }
        @media all and (max-width: 500px) {
            font-size: 30px;
        }
        @media all and (max-width: 380px) {
            font-size: 24px;
        }
    }
}