@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=40b3601a-8232-4013-b4a2-7066540bd4f4&fontids=5608561");
*,
*:after,
*:before {
  box-sizing: border-box;
  margin: 0; }

ul[class] {
  margin: 0;
  padding: 0;
  list-style: none; }

/* colors */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

button::-moz-focus-inner {
  outline: none;
  border: 0; }

a:focus,
button:focus {
  outline: none; }

/* fonts */
@font-face {
  font-family: "Arial MT W01 Black";
  src: url("/fonts/5608561/dbfce1da-7b55-4d7a-830d-c6557d33c24f.woff2") format("woff2"), url("/fonts/5608561/f552a731-c20f-4737-abd7-fef07295897e.woff") format("woff"); }

.f-logo {
  font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
  font-weight: normal;
  color: #585858;
  font-size: 26px;
  line-height: 30px; }
  @media all and (max-width: 600px) {
    .f-logo {
      font-size: 20px;
      line-height: 24px; } }

.f-menu {
  font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
  font-weight: normal;
  color: #585858;
  font-size: 18px;
  line-height: 20px; }
  @media all and (max-width: 600px) {
    .f-menu {
      font-size: 16px;
      line-height: 20px; } }

.f-footer {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #585858;
  font-size: 14px;
  line-height: 20px; }
  @media all and (max-width: 600px) {
    .f-footer {
      font-size: 12px;
      line-height: 16px; } }

.f-h1 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fa5226;
  font-size: 45px;
  line-height: 54px;
  margin: 0; }

.f-h2 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #585858;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 4px; }

.f-h3 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #585858;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 24px; }
  @media all and (max-width: 600px) {
    .f-h3 {
      padding-bottom: 0; } }

.f-h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #585858;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 8px; }

.f-bodyp {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
  color: #585858;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 8px; }

a {
  text-decoration: none;
  color: inherit; }

.arialblack {
  font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif !important; }

/* layout */
body {
  min-height: 110vh;
  background: #eceae9;
  padding-top: 70px;
  padding-bottom: 20px; }
  @media all and (max-width: 800px) {
    body {
      padding-top: 15px; } }

.wrapper {
  width: 1196px;
  max-width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto; }

img {
  max-width: 100%;
  height: auto; }

@media all and (max-width: 800px) {
  .img--full {
    max-width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; } }

.imgwrapper-home {
  position: relative;
  display: flex;
  align-content: center;
  align-items: center; }
  .imgwrapper-home span {
    display: none; }
    @media all and (max-width: 600px) {
      .imgwrapper-home span {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        color: #fa5226;
        margin: auto;
        font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
        text-align: center;
        font-size: 40px; } }
    @media all and (max-width: 500px) {
      .imgwrapper-home span {
        font-size: 30px; } }
    @media all and (max-width: 380px) {
      .imgwrapper-home span {
        font-size: 24px; } }

/* header */
.header {
  border-bottom: 1px solid #d3d3d3;
  margin-bottom: 20px;
  position: relative; }

.logo {
  width: 2000px;
  padding-bottom: 5px; }

.nav {
  display: flex;
  align-items: center; }

.mainnav {
  display: flex; }

.nav-item {
  padding-left: 30px; }

.nav-link {
  padding-bottom: 8px;
  margin-bottom: -8px;
  display: block;
  border-bottom: 1px solid transparent; }
  .nav-link--active {
    border-bottom: 1px solid #585858; }

@media all and (max-width: 800px) {
  .header {
    margin-bottom: 15px;
    max-width: 100%;
    border: none; }
  .logo {
    width: auto;
    padding-left: 20px;
    display: block; }
  .nav {
    display: block;
    padding-bottom: 0;
    border-bottom: 1px solid #d3d3d3; }
  .mainnav {
    display: block;
    margin-top: 6px; }
  .nav-item {
    padding: 0; }
  .nav-link {
    padding: 6px 20px 4px 20px;
    border-top: 1px solid #d3d3d3;
    display: block;
    margin: 0; }
  .has-js .nav-toggle {
    display: block !important;
    font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
    position: absolute;
    right: 20px;
    top: 8px;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer; }
    .has-js .nav-toggle:focus {
      outline: none; }
  .has-js .mainnav {
    opacity: 0;
    max-height: 0px;
    transition: all 240ms ease-out; }
    .has-js .mainnav.active {
      opacity: 1;
      max-height: 300px;
      margin-top: 4px; } }

@media all and (max-width: 600px) {
  .has-js .nav-toggle {
    top: 4px; } }

/* footer */
.footer {
  border-top: 1px solid #d3d3d3;
  margin-top: 20px;
  padding-top: 10px; }
  .footer a:hover,
  .footer a:focus {
    text-decoration: underline; }
  .footer strong {
    font-family: Arial Black, Arial Bold, Gadget, sans-serif;
    font-weight: normal; }

@media all and (max-width: 700px) {
  .footer-beta {
    margin-top: 10px; } }

@media all and (min-width: 701px) {
  .footer {
    display: flex;
    justify-content: space-between; }
  .footer-beta {
    text-align: right; } }

@media all and (min-width: 1100px) {
  .footer-alpha {
    display: flex; }
    .footer-alpha p + p {
      margin-left: 60px; } }

ul.projectsthumbnails {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 0 -10px; }
  ul.projectsthumbnails li {
    height: 200px;
    flex-grow: 1;
    margin: 40px 10px 0 10px;
    max-width: calc(25% - 20px); }
  ul.projectsthumbnails a {
    display: block;
    height: 100%;
    width: 100%; }
  ul.projectsthumbnails img {
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom; }

@media all and (max-width: 850px) {
  ul.projectsthumbnails li {
    max-width: calc(33.333% - 20px); } }

@media all and (max-width: 550px) {
  ul.projectsthumbnails {
    margin-top: -20px; }
  ul.projectsthumbnails li {
    margin: 20px 10px 0 10px;
    max-width: calc(50% - 20px); } }

@media all and (max-width: 360px) {
  ul.projectsthumbnails li {
    max-width: calc(100% - 20px); } }

@media all and (min-width: 800px) {
  ul.contentlist,
  .contentlist_wrapper {
    padding-top: 40px; }
  .contentlist li,
  .contentlist_wrapper {
    column-count: 2;
    column-gap: 20px;
    padding-bottom: 40px; }
    .contentlist li:last-of-type,
    .contentlist_wrapper:last-of-type {
      padding-bottom: 0; }
    .contentlist li .f-h2,
    .contentlist_wrapper .f-h2 {
      padding-bottom: 15px;
      font-weight: normal;
      font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif; }
    .contentlist li .f-h3,
    .contentlist_wrapper .f-h3 {
      font-weight: normal; } }

.contentlist__beta {
  break-inside: avoid; }

@media all and (max-width: 799px) {
  .contentlist li,
  .contentlist_wrapper {
    padding-bottom: 25px; }
    .contentlist li:last-of-type,
    .contentlist_wrapper:last-of-type {
      padding-bottom: 0; }
    .contentlist li .f-h2,
    .contentlist_wrapper .f-h2 {
      padding-top: 10px;
      padding-bottom: 0; }
    .contentlist li .f-h3,
    .contentlist_wrapper .f-h3 {
      font-weight: normal;
      padding-bottom: 5px; }
    .contentlist li img,
    .contentlist_wrapper img {
      margin: 0 -20px;
      max-width: calc(100% + 40px);
      min-width: calc(100% + 40px); }
  .contentlist li {
    padding-bottom: 35px; }
  .contentlist_wrapper img {
    display: none; } }

@media all and (min-width: 800px) {
  ul.projectlist {
    padding-top: 10px; }
  .projectlist li {
    column-count: 2;
    column-gap: 20px;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #585858; }
    .projectlist li:last-of-type {
      padding-bottom: 0;
      border-bottom: none; }
    .projectlist li .f-h2 {
      font-weight: normal;
      font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif; }
    .projectlist li .f-h3 {
      font-weight: normal;
      padding-bottom: 0; }
    .projectlist li .f-h3 + .f-h3 {
      padding-bottom: 24px; }
    .projectlist li br {
      display: block;
      margin: 4px; } }

.projectlist__beta {
  break-inside: avoid; }

@media all and (max-width: 799px) {
  .projectlist li {
    padding-bottom: 35px; }
    .projectlist li:last-of-type {
      padding-bottom: 0; }
    .projectlist li .f-h2 {
      padding-top: 10px;
      padding-bottom: 0; }
    .projectlist li .f-h3 {
      font-weight: normal;
      padding-bottom: 5px; }
    .projectlist li img {
      margin: 0 -20px;
      max-width: calc(100% + 40px);
      min-width: calc(100% + 40px); } }

.gallery-trigger {
  position: relative; }
  .gallery-trigger button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: none;
    border: none;
    text-indent: -2020em;
    width: 100%;
    height: 100%;
    cursor: pointer; }

.has-js .gallery-trigger button {
  display: block !important; }

.dialog[aria-hidden="true"] {
  display: none; }

[aria-hidden="true"] .dialog-overlay {
  opacity: 0;
  z-index: -10;
  transition: opacity 340ms; }

.dialog-overlay {
  position: fixed;
  top: 0;
  lf: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #eceae9;
  height: 100%;
  opacity: 1;
  z-index: 0;
  transition: opacity 340ms; }

.dialog-content {
  width: 1196px;
  max-width: calc(100% - 40px);
  top: 60px;
  max-height: 90vh;
  margin: 0 auto;
  border: none;
  background: none;
  position: absolute; }

.dialog-close {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 50px;
  background: none;
  border: none;
  background: transparent url(/img/icons/close.svg) no-repeat 0 0;
  width: 28px;
  height: 23px;
  display: block;
  padding: 0;
  text-indent: -2020em; }
  .dialog-close:focus {
    outline: none; }

figcaption {
  text-align: center;
  padding-top: 20px; }

figure img {
  max-width: auto;
  width: 100%; }

.tns-outer .tns-outer .tns-outer .tns-controls {
  display: none !important; }

.tns-outer {
  position: relative;
  max-height: 83vh; }
  .tns-outer img {
    object-fit: contain; }
  .tns-outer li {
    height: 83vh; }
    .tns-outer li img {
      object-fit: contain;
      display: block;
      height: calc(83vh - 40px); }

[data-controls="next"] {
  background: none;
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #eceae9 url(/img/icons/next.svg) no-repeat 0 0;
  width: 32px;
  height: 32px;
  display: block;
  background-size: cover;
  z-index: 1000;
  text-indent: -2020em; }

[data-controls="prev"] {
  background: none;
  border: none;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #eceae9 url(/img/icons/previous.svg) no-repeat 0 0;
  width: 32px;
  height: 32px;
  display: block;
  background-size: cover;
  z-index: 1000;
  text-indent: -2020em; }

.tns-controls:focus {
  outline: none; }

.tns-outer {
  padding: 0 !important; }

.tns-outer [hidden] {
  display: none !important; }

.tns-outer [aria-controls],
.tns-outer [data-action] {
  cursor: pointer; }

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s; }

.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }

.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }

.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s; }

.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important; }

.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6; }

.tns-lazy-img.tns-complete {
  opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal,
.tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }

.tns-vpfix > div,
.tns-vpfix > li {
  display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: 2333.3333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }

.tns-t-ct:after {
  content: '';
  display: table;
  clear: both; }

.tns-t-ct > div {
  width: 1.4285714%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left; }

/*# sourceMappingURL=index.css.map */