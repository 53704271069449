/* header */

.header {
    border-bottom: 1px solid $grey;
    margin-bottom: 20px;
    position: relative;
}

.logo {
    width: 2000px;
    padding-bottom: 5px;
}

.nav {
    display: flex;
    align-items: center;
}

.mainnav {
    display: flex;
}

.nav-item {
    padding-left: 30px;
}

.nav-link {
    padding-bottom: 8px;
    margin-bottom: -8px;
    display: block;
    border-bottom: 1px solid transparent;
    &--active {
        border-bottom: 1px solid $dark;
    }
}

@media all and (max-width: 800px) {
    .header {
        margin-bottom: 15px;
        max-width: 100%;
        border: none;
    }
    .logo {
        width: auto;
        padding-left: 20px;
        display: block;
    }
    .nav {
        display: block;
        padding-bottom: 0;
        border-bottom: 1px solid $grey;
    }
    .mainnav {
        display: block;
        margin-top: 6px;
    }
    .nav-item {
        padding: 0;
    }
    .nav-link {
        padding: 6px 20px 4px 20px;
        border-top: 1px solid $grey;
        display: block;
        margin: 0;
    }
    .has-js {
        .nav-toggle {
            display: block !important;
            font-family: "Arial MT W01 Black", Arial Black, Arial Bold, Gadget, sans-serif;
            position: absolute;
            right: 20px;
            top: 8px;
            margin: 0;
            padding: 0;
            border: none;
            background: none;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
        .mainnav {
            opacity: 0;
            max-height: 0px;
            transition: all 240ms ease-out;
            &.active {
                opacity: 1;
                max-height: 300px;
                margin-top: 4px;
            }
        }
    }
}

@media all and (max-width: 600px) {
    .has-js .nav-toggle {
        top: 4px;
    }
}