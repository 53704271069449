*,
*:after,
*:before {
    box-sizing: border-box;
    margin: 0;
}

ul[class] {
    margin: 0;
    padding: 0;
    list-style: none;
}